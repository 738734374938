<template>
  <div class="index-box">
    <div class="content-box">
      <img src="@/assets/images/ribbon.png" alt class="ribbon-bg" />
      <div class="content-title">
        <img src="@/assets/images/text.png" alt />
      </div>
      <div class="content-btn" @click="goAnswerPage('preemptive')">开始答题</div>
    </div>
    <div class="back-btn" @click="backQuestionType">返回</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {};
  },
  methods: {
    goAnswerPage(value) {
      this.$router.push("/answerPage?type=" + value);
    },
    backQuestionType() {
      this.$router.push("/questionType");
    },
  },
};
</script>


<style scoped>
.index-box {
  width: 100%;
  height: 100vh;
  /* background: url("./../../assets/images/bg.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  position: relative;
}
.content-box {
  position: relative;
  z-index: 1;
  padding-top: 3.71rem;
}
.content-box .ribbon-bg {
  position: absolute;
  z-index: -1;
  /* width: 10.5rem;
  height: 4.7rem; */
  width: 15.75rem;
  height: 7.05rem;
  top: 1.07rem;
  left: 50%;
  transform: translateX(-50%);
}
.content-box .content-title {
  font-size: 1.6rem;
  font-family: YouSheBiaoTiHei;
  /* color: #f4b485; */
  line-height: 2.08rem;
  letter-spacing: 6px;
  text-shadow: 0px 20px 24px rgba(79, 0, 1, 0.13);
  background: linear-gradient(to bottom, #ffd4a3 0%, #f68263 100%);
  background: -webkit-linear-gradient(to bottom, #ffd4a3 0%, #f68263 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-box .content-title img {
  /* width: 6.15rem;
  height: 1.58rem; */
  width: 12.3rem;
  height: 3.16rem;
}

.content-btn {
  width: 5.41rem;
  height: 1.62rem;
  margin: 0.6rem auto;
  font-size: 0.4rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #ffec91;
  line-height: 1.5rem;
  letter-spacing: 1px;
  text-align: center;
  background-image: url(./../../assets/images/btnBg2.png);
  background-repeat: no-repeat;
  background-size: 5.41rem 1.62rem;
  background-position: center;
  text-align: center;
  cursor: pointer;
}
.content-btn:hover {
  opacity: 0.85;
}
</style>