import { render, staticRenderFns } from "./answerPageRisk.vue?vue&type=template&id=331f4d8c&scoped=true&"
import script from "./answerPageRisk.vue?vue&type=script&lang=js&"
export * from "./answerPageRisk.vue?vue&type=script&lang=js&"
import style1 from "./answerPageRisk.vue?vue&type=style&index=1&id=331f4d8c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "331f4d8c",
  null
  
)

export default component.exports