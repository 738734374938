import request from "@/utils/request";

// 获取定制记录列表
export function getItemList(query) {
  return request({
    url: "pc",
    method: "get",
    params: query,
  });
}

// 获取定制记录详情
export function getItemInfo(id) {
  return request({
    url: `pc/${id}`,
    method: "get",
  });
}

export function getItemDetail(id) {
  return request({
    url: `pc/detail/${id}`,
    method: "get",
  });
}





export function getFinished(query) {
  return request({
    url: `pc/check`,
    method: "get",
    params: query,
  });
}

export function delAllItems(query) {
  return request({
    url: `pc/del`,
    method: "get",
    params: query,
  });
}