<template>
  <div class="index-box">
    <div class="content-box">
      <div class="risk-box" :class="questionType == 'requiredQuestions'?'require-bg' : 'risk-bg'">
        <div class="risk-title">
          <img src="@/assets/images/leftIcon.png" alt />
          <span>{{questionType == 'requiredQuestions'?'单枪匹马':'迎难而上'}}</span>
          <img src="@/assets/images/rightIcon.png" alt />
        </div>

        <div
          class="inter-btn-first-box"
          @click="goQuestionList(questionType == 'requiredQuestions'?'require1':'risk1')"
        >
          <div class="inter-btn-first">进入答题 ></div>
        </div>
        <div class="inter-btn-second-box">
          <div
            class="inter-btn-second"
            @click="goQuestionList(questionType == 'requiredQuestions'?'require2':'risk2')"
          >进入答题 ></div>
        </div>
        <div class="inter-btn-third-box" v-if="questionType == 'requiredQuestions'">
          <div
            class="inter-btn-third"
            @click="goQuestionList('require3')"
          >进入答题 ></div>
        </div>
      </div>
    </div>
    <div class="back-btn" @click="backQuestionType">返回</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      questionType: "",
    };
  },
  mounted() {
    console.log(this.$route.query.questionType);
    this.questionType = this.$route.query.questionType;
  },
  methods: {
    goQuestionList(value) {
      this.$router.push("/questionList?type=" + value);
    },
    backQuestionType() {
      this.$router.push("/questionType");
    },
  },
};
</script>


<style scoped>
.index-box {
  width: 100%;
  height: 100vh;
  /* background: url("./../../assets/images/bg.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 0.78rem;
}

.content-box .risk-box {
  position: relative;
  width: 15.8rem;
  height: 8.92rem;

  background-size: 15.8rem 8.92rem;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.content-box .risk-bg {
  background-image: url("./../../assets/images/riskBg.png");
}
.content-box .require-bg {
  width: 20.95rem;
  height: 9.2rem;
  /* background-image: url("./../../assets/images/requiredBg.png"); */
  background-image: url("./../../assets/images/requiredBg1.png");
  background-size: 20.95rem 9.2rem;

}
.risk-box .risk-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-family: YouSheBiaoTiHei;
  color: #f3ffca;
  line-height: 1.04rem;
  letter-spacing: 3px;
  text-shadow: 0px 2px 0px rgba(255, 224, 125, 0.25);
  background: linear-gradient(180deg, #fff9b6 0%, #ffd96e 100%);
  background: -webkit-linear-gradient(180deg, #fff9b6 0%, #ffd96e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0.1rem;
}
.risk-box .risk-title img {
  width: 3.2rem;
  height: 0.03rem;
  vertical-align: middle;
}
.risk-box .risk-title span {
  margin: 0 0.31rem;
  vertical-align: middle;
}

.risk-box .inter-btn-first-box {
  position: absolute;
  bottom: 2rem;
  left: 0.81rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #b43b22;
  line-height: 0.45rem;
  cursor: pointer;
}

.risk-box .inter-btn-second-box {
  position: absolute;
  top: 3.18rem;
  right: 0.81rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #b43b22;
  line-height: 0.45rem;
  cursor: pointer;
}
.require-bg .inter-btn-first-box {
  bottom: 3.12rem;
  left: 1.16rem;
}
.require-bg .inter-btn-second-box{
  top: 3rem;
  right: 7.04rem;
}
.require-bg .inter-btn-third-box {
  position: absolute;
  top: 3.62rem;
  right: 0.81rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #b43b22;
  line-height: 0.45rem;
  cursor: pointer;
}
.risk-box .inter-btn-first-box:hover,
.risk-box .inter-btn-second-box:hover,
.risk-box .inter-btn-third-box:hover {
  color: #e95637 !important;
}
</style>