<template>
  <div class="index-box">
    <div class="content-box">
      <div class="question-list-title">
        <img src="@/assets/images/leftIcon.png" alt />
        <span>{{ title }}</span>
        <img src="@/assets/images/rightIcon.png" alt />
      </div>
      <div class="question-list" v-if="type == 'risk1' || type == 'risk2'">
        <!-- :style="{boxShadow:item.isCheck ? '' : '0 17px 23px 0 rgba(147, 35, 23, 0.5)!important'} " -->
        <div
          class="question-item"
          :class="item.isCheck ? '':'shdow-item'"
          v-for="(item, index) of questionList"
          :key="index"
        >
          <img v-if="item.isCheck" :src="require(`@/assets/images/riskgrey${index + 1}.png`)" alt />
          <img
            @click="goAnswerPage(index)"
            v-else
            :src="require(`@/assets/images/risk${index + 1}.png`)"
            alt
          />
        </div>
      </div>

      <div class="question-list" v-else>
        <!-- shdow-item -->
        <div
          class="question-item"
          :class="item.status==1 ? '':'shdow-item'"
          v-for="(item,index) in finishedList"
          :key="index"
        >
          <img v-if="item.status == 1" :src="require(`@/assets/images/grey${index+1}.png`)" alt />
          <img
            v-else
            :src="require(`@/assets/images/${index+1}.png`)"
            alt
            @click="goAnswerPage(index+1)"
          />
        </div>
      </div>
    </div>
    <div class="back-btn" @click="backRiskQuestion">返回</div>
  </div>
</template>

<script>
import { getItemList, getFinished } from "@/api/item";
const optionList = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
  13: "M",
  14: "N",
  15: "O",
  16: "P",
};
export default {
  name: "index",
  data() {
    return {
      optionList: optionList,
      title: "",
      type: "",
      questionList: undefined,
      finishedList: undefined,
      topicType: undefined,
      subType: "",
      query: {
        type: undefined,
        subType: undefined,
        groupIndex: undefined,
      },
    };
  },
  mounted() {
    console.log(this.$route.query.type);
    this.type = this.$route.query.type;
    if (this.$route.query.type == "risk1") {
      this.query.type = "risk";
      this.query.subType = "20";
      this.title = "20分";
    } else if (this.$route.query.type == "risk2") {
      this.query.type = "risk";
      this.query.subType = "30";
      this.title = "30分";
    }
    if (this.$route.query.type == "require1") {
      this.title = "第一轮";
      this.subType = "first";
    } else if (this.$route.query.type == "require2") {
      this.title = "第二轮";
      this.subType = "second";
    } else if (this.$route.query.type == "require3") {
      this.title = "第三轮";
      this.subType = "third";
    }
    if (
      this.$route.query.type == "risk1" ||
      this.$route.query.type == "risk2"
    ) {
      getItemList(this.query).then((res) => {
        if (res.data) {
          this.questionList = res.data;
        }
      });
    } else if (
      this.$route.query.type == "require1" ||
      this.$route.query.type == "require2" ||
      this.$route.query.type == "require3"
    ) {
      getFinished({ subType: this.subType }).then((res) => {
        this.finishedList = res.data;
        // console.log(res);
      });
    }
  },
  methods: {
    goAnswerPage(index) {
      if (
        this.$route.query.type == "risk1" ||
        this.$route.query.type == "risk2"
      ) {
        this.$router.push({
          path: "/answerPageRisk",
          query: {
            id: this.questionList[index].id,
            type: this.type,
          },
        });
      } else {
        this.$router.push({
          path: "/answerPage",
          query: {
            index: index,
            type: this.type,
          },
        });
      }
    },

    backRiskQuestion() {
      this.$router.push({
        path: "/riskQuestion",
        query: {
          questionType:
            this.$route.query.type == "risk1" ||
            this.$route.query.type == "risk2"
              ? "riskQuestions"
              : "requiredQuestions",
        },
      });
    },
  },
};
</script>

<style scoped>
.index-box {
  width: 100%;
  height: 100vh;
  /* background: url("./../../assets/images/bg.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 0.88rem;
}
.content-box .question-list-title {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8rem;
  font-family: YouSheBiaoTiHei;
  color: #f3ffca;
  line-height: 1.04rem;
  letter-spacing: 3px;
  text-shadow: 0px 2px 0px rgba(255, 224, 125, 0.25);
  background: linear-gradient(180deg, #fff9b6 0%, #ffd96e 100%);
  background: -webkit-linear-gradient(180deg, #fff9b6 0%, #ffd96e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content-box .question-list-title img {
  width: 3.2rem;
  height: 0.03rem;
}
.content-box .question-list-title span {
  margin: 0 0.46rem;
}

.content-box .question-list {
  margin: 1.02rem auto 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 17.8rem; */
  width: 13.2rem;
}

.content-box .question-list .question-item {
  width: 4rem;
  height: 2.46rem;
  /* box-shadow: 0 17px 23px 0 rgba(147, 35, 23, 0.5); */
  margin-bottom: 0.6rem;
  border-radius: 20px;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
}
/* .content-box .question-list .question-item:hover {
  box-shadow: 0 17px 23px 0 rgba(147, 35, 23, 0.2);
 
} */
.content-box .question-list .shdow-item {
  box-shadow: 0 17px 23px 0 rgba(147, 35, 23, 0.5);
}
.content-box .question-list .shdow-item:hover {
  box-shadow: 0 17px 23px 0 rgba(147, 35, 23, 0.2);
}
.content-box .question-list .question-item img {
  width: 4rem;
  height: 2.46rem;
}
</style>
