import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import router from "../router";

let serverUrl = "/api/";
if (process.env.NODE_ENV === "production") {
  serverUrl = "/admin";
}

export function getBaseServer() {
  return serverUrl;
}

export function toMedia(url) {
  if (url && url.indexOf("http") > -1) {
    return url;
  }
  return serverUrl + url;
}

// create an axios instance
const service = axios.create({
  baseURL: serverUrl, // url =  base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers["Content-Type"] = "application/json"; // x-www-form-urlencoded
    // eslint-disable-next-line no-prototype-builtins
    if (config.hasOwnProperty("data") && config.data.hasOwnProperty("file")) {
      // config.headers['Content-Type'] = 'multipart/form-data'
      let formdata = new FormData();
      formdata.set("file", config.data.file);
      config.data = formdata;
    }

    // 添加签名头信息
    const timestamp = new Date().getTime().toString();
    let obj = { appid: "1111", timestamp: timestamp };
    let fixedObj = Object.assign({}, obj, config.params, config.data);
    // 签名数据
    // eslint-disable-next-line no-prototype-builtins
    if (fixedObj.hasOwnProperty("brinSignData")) {
      delete config.data.brinSignData;
      fixedObj = Object.assign({}, obj, config.params, fixedObj.brinSignData);
    }
    // 签名
    let newData = {};
    Object.keys(fixedObj)
      .sort()
      .map((key) => {
        if (
          fixedObj[key] !== undefined &&
          fixedObj[key] !== null &&
          fixedObj[key] !== ""
        ) {
          if (["page", "limit", "file", "order", "field"].indexOf(key) === -1) {
            newData[key] = fixedObj[key];
          }
        }
      });

    let sha1 = require("sha1");
    // console.log(JSON.stringify(newData) + '9HbLBnWsl83WoVXo')
    // console.log(sha1(JSON.stringify(newData) + '9HbLBnWsl83WoVXo'))
    config.headers["brintechapi-appid"] = "1111";
    config.headers["brintechapi-timestamp"] = timestamp;
    config.headers["brintechapi-sign"] = sha1(
      JSON.stringify(newData) + "9HbLBnWsl83WoVXo"
    );

    if (config.url.indexOf("civilization/member/downloadImage") > -1) {
      config.timeout = 0;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (
      response.headers["content-type"] ===
        "application/msexcel;charset=UTF-8" ||
      response.headers["content-type"].startsWith("application/octet-stream;")
    ) {
      return response;
    }
    // if the custom code is not 200, it is judged as an error.
    if (res.code !== 200) {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
        customClass: "focus-message",
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm(
          "You have been logged out, you can cancel to stay on this page, or log in again",
          "Confirm logout",
          {
            confirmButtonText: "Re-Login",
            cancelButtonText: "Cancel",
            type: "warning",

            customClass: "focus-message",
          }
        ).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        });
      }
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      // eslint-disable-next-line no-prototype-builtins
      if (response.headers.hasOwnProperty("authorization")) {
        res["token"] = response.headers.authorization;
        store.dispatch("user/setUserToken");
      }
      return res;
    }
  },
  (error) => {
    console.log(error.response.data.code);
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.headers.hasOwnProperty("authorization")) {
      store.dispatch("user/setUserToken");
    }
    if (
      error.response.data.code === 1402 ||
      error.response.data.message === "登录过期，请重新登录"
    ) {
      store.dispatch("user/logout");
      router.push(`/`);
      return;
    } else if (error.response.data.message) {
      error.message = error.response.data.message;
    }
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
      customClass: "focus-message",
    });
    return Promise.reject(error);
  }
);

export default service;
