<template>
  <div class="index-box">
    <div class="content-box">
      <div class="center-box">
        <img src="@/assets/images/20Icon.svg" alt v-if="type == 'risk1'" class="type-icon" />
        <img src="@/assets/images/30Icon.svg" alt v-if="type == 'risk2'" class="type-icon" />
        <div class="question-type-title">{{ this.title }}</div>
        <div class="question-type-content">
          <div class="title-number-box">
            <span>{{ questionIndex }}</span>
            / {{ questionList.length }}
          </div>

          <div class="question-box">
            <template v-for="(item, index) in questionList">
              <div :key="index" v-if="questionIndex == index + 1">
                <div
                  class="choose-answer-box"
                  v-if="
                    item.type == 'single_choice' ||
                    item.type == 'multiple_choice'
                  "
                >
                  <div class="choose-answer-title">{{ item.name }}</div>
                  <div class="choose-answer-list">
                    <div
                      class="choose-answer-item"
                      v-for="(sonItem, sonIndex) in item.chooseList"
                      :key="sonIndex"
                    >{{ optionList[sonItem.orderIndex] }}.{{ sonItem.name }}</div>
                  </div>
                  <div class="answer" v-if="showAnswer">答案：{{ item.answer }}</div>
                </div>
                <div class="short-answer" v-if="item.type == 'short_answer'" v-html="item.name"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="operate-btn-box">
        <el-button type="primary" class="btn-item btn-item-1" @click="goIndex">返回活动页</el-button>
        <el-button type="primary" class="btn-item btn-item-1" @click="goQuestionType">返回题型页</el-button>
        <el-button
          type="primary"
          class="btn-item btn-item-1"
          v-if="type != 'preemptive'"
          @click="goScorePage"
        >返回分数页</el-button>
        <el-button
          type="primary"
          class="btn-item btn-item-1"
          v-if="type != 'preemptive'"
          @click="goQuestionList"
        >返回本轮分数页</el-button>
        <el-button type="primary" class="btn-item btn-item-2" @click="showPopup">查看答案</el-button>
        <el-button
          type="primary"
          class="btn-item btn-item-2"
          @click="nextQuestion"
          v-if="questionIndex != questionList.length"
        >下一题</el-button>
        <!-- <div class="btn-item btn-item-1">返回活动页</div>
        <div class="btn-item btn-item-1">返回题型页</div>
        <div class="btn-item btn-item-1">返回分数页</div>
        <div class="btn-item btn-item-2" @click="showPopup">查看答案</div>
        <div class="btn-item btn-item-2" @click="nextQuestion">下一题</div>-->
      </div>
    </div>

    <el-dialog :visible.sync="centerDialogVisible">
      <el-main v-loading="dialogLoading">
        <div class="answer-popup-box">
          <div class="answer-popup">
            <span>答案：</span>
            <div>{{ questionList[questionIndex - 1].answer }}</div>
          </div>

          <div class="answer-popup-close-btn" @click="closePopup">
            <img src="@/assets/images/closeBtn.svg" alt />
          </div>
        </div>
      </el-main>
    </el-dialog>

    <el-dialog :visible.sync="showConfirmPopup" custom-class="popup">
      <el-main v-loading="dialogLoading">
        <div class="confirm-popup-box">
          <div class="confirm-popup">
            <img src="@/assets/images/closeIcon.png" alt @click="closeConfirmPopup" />
            <div class="confirm-popup-title">确认返回</div>
            <div class="confirm-popup-content">确认返回{{popupContent}}页吗？</div>
            <div class="confirm-popup-btn-box">
              <div class="confirm-popup-btn cancel-btn" @click="closeConfirmPopup">取消</div>
              <div class="confirm-popup-btn confirm-btn" @click="confirm">确认</div>
            </div>
          </div>
        </div>
      </el-main>
    </el-dialog>
  </div>
</template>

<script>
import { getItemDetail, getItemInfo } from "@/api/item";

const optionList = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
  13: "M",
  14: "N",
  15: "O",
  16: "P",
};
export default {
  name: "index",
  data() {
    return {
      optionList: optionList,
      questionIndex: 1,
      title: "",
      type: "",
      centerDialogVisible: false,

      dialogLoading: false,
      showConfirmPopup: false,
      showAnswer: false,
      topicType: "single_choice",
      questionList: [
        {
          answer: "",
          name: "",
          id: "",
          type: "",
          chooseList: [],
        },
      ],

      index: undefined,
      query: {
        type: undefined,
        subType: undefined,
        groupIndex: undefined,
      },
    };
  },
  mounted() {
    this.query.groupIndex = undefined;
    this.type = this.$route.query.type;
    this.index = this.$route.query.index;
    console.log(this.type, this.index);
    if (this.$route.query.type == "risk1") {
      this.query.type = "risk";
      this.query.subType = "20";
      this.title = "20分";
    } else if (this.$route.query.type == "risk2") {
      this.query.type = "risk";
      this.query.subType = "30";
      this.title = "30分";
    }
    getItemDetail(this.$route.query.id).then((res) => {
      if (res.data) {
        this.questionList = res.data;
        this.topicType = res.data[0].type;
        for (var i = 0; i < this.questionList.length; i++) {
          if (this.questionList[i].type != "short_answer") {
            this.questionList[i].answer = "";
            for (var j = 0; j < this.questionList[i].chooseList.length; j++) {
              if (this.questionList[i].chooseList[j].isRight) {
                this.questionList[i].answer +=
                  "," +
                  this.optionList[
                    this.questionList[i].chooseList[j].orderIndex
                  ];
              }
            }
            console.log(this.questionList[i].answer);
            this.questionList[i].answer = this.questionList[i].answer.substr(
              1,
              this.questionList[i].answer.length - 1
            );
          }
          this.questionList[i].isLooked = false;
        }
      }
    });
  },
  methods: {
    nextQuestion() {
      this.showAnswer = false;
      if (this.questionIndex < this.questionList.length) {
        this.questionIndex++;
        this.topicType = this.questionList[this.questionIndex - 1].type;
      } else {
        console.log("已经是最后一题");
      }
    },
    showPopup() {
      // 已经查看过答案
      this.questionList[this.questionIndex - 1].isLooked = true;
      if (
        this.topicType == "single_choice" ||
        this.topicType == "multiple_choice"
      ) {
        this.showAnswer = true;
      } else {
        this.centerDialogVisible = true;
      }
      getItemInfo(this.questionList[this.questionIndex - 1].id).then((res) => {
        console.log(res);
      });
    },
    closePopup() {
      this.centerDialogVisible = false;
    },
    // 轮次页
    goScorePage() {
      // this.$router.replace("/riskQuestion");
      if (
        this.questionIndex == this.questionList.length &&
        this.questionList[this.questionIndex - 1].isLooked
      ) {
        this.$router.push({
          path: "/riskQuestion",
          query: {
            questionType:
              this.type == "risk1" || this.type == "risk2"
                ? "riskQuestions"
                : "requiredQuestions",
          },
        });
      } else {
        this.popupContent = "分数";
        this.showConfirmPopup = true;
      }
    },
    goQuestionList() {
      // 返回本轮次页
      if (
        this.questionIndex == this.questionList.length &&
        this.questionList[this.questionIndex - 1].isLooked
      ) {
        this.$router.push({
          path: "/questionList",
          query: {
            type: this.type,
          },
        });
      } else {
        // 出现弹窗
        this.popupContent = "本轮分数";
        this.showConfirmPopup = true;
      }
    },
    goIndex() {
      if (
        this.questionIndex == this.questionList.length &&
        this.questionList[this.questionIndex - 1].isLooked
      ) {
        this.$router.push({ name: "index" });
      } else {
        this.popupContent = "活动";
        this.showConfirmPopup = true;
      }
    },
    goQuestionType() {
      if (
        this.questionIndex == this.questionList.length &&
        this.questionList[this.questionIndex - 1].isLooked
      ) {
        this.$router.push({ name: "questionType" });
      } else {
        this.popupContent = "题型";
        this.showConfirmPopup = true;
      }
    },

    closeConfirmPopup() {
      // this.popupContent = "";
      this.showConfirmPopup = false;
    },
    confirm() {
      if (this.popupContent == "本轮分数") {
        this.$router.push({
          path: "/questionList",
          query: {
            type: this.type,
          },
        });
      } else if (this.popupContent == "分数") {
        this.$router.push({
          path: "/riskQuestion",
          query: {
            questionType:
              this.type == "risk1" || this.type == "risk2"
                ? "riskQuestions"
                : "requiredQuestions",
          },
        });
      } else if (this.popupContent == "活动") {
        this.$router.push({ name: "index" });
      } else if (this.popupContent == "题型") {
        this.$router.push({ name: "questionType" });
      }
    },
  },
};
</script>

<style scoped></style>
<style scoped>
.index-box {
  width: 100%;
  height: 100vh;
  /* background: url("./../../assets/images/bg.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 0.81rem;
}
.index-box ::v-deep .el-dialog {
  background: transparent;
  box-shadow: unset;
  height: 84vh;
  width: 18.68rem !important;
  margin-top: 8vh !important;
}
.index-box ::v-deep .el-card__body,
.index-box ::v-deep .el-main {
  padding: 0 !important;
  height: 100% !important;
}
.index-box ::v-deep .el-dialog__header {
  display: none;
}
.index-box ::v-deep .el-dialog__body {
  padding: 0 !important;
  height: 84vh;
}
.index-box .answer-popup-box {
  display: flex;
  height: 84vh;
  flex-direction: column;
}
.index-box .answer-popup {
  padding: 0.2rem;
  height: 100%;
  flex: 1;
  background-image: url("./../../assets/images/popupBg.png");
  background-repeat: no-repeat;
  background-size: 100% calc(84vh - 0.89rem);
  padding: 0.85rem 1.09rem 1.11rem 0.84rem;
  display: flex;
}
.index-box .answer-popup span {
  /* font-size: 0.38rem; */
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f80c0c;
  /* line-height: 0.7rem; */
  flex-shrink: 0;
  margin-right: 0.03rem;
  font-size: 0.62rem;
  line-height: 0.8rem;
}
.index-box .answer-popup div {
  /* font-size: 0.38rem; */
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5d0505;
  /* line-height: 0.7rem; */
  text-align: left;
  height: calc(84vh - 0.89rem - 0.85rem - 1.11rem);
  overflow-y: scroll;
  white-space: pre-wrap;
  font-size: 0.62rem;
  line-height: 0.8rem;
}
.answer-popup div::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  /* background: #f4dcbf; */
}
.answer-popup div::-webkit-scrollbar-thumb {
  background: #f4dcbf;
  border-radius: 3px;
}
.index-box .answer-popup-box .answer-popup-close-btn {
  width: 0.52rem;
  height: 0.52rem;
  margin: 0.37rem auto 0;
  flex-shrink: 0;
  cursor: pointer;
}
.index-box .answer-popup-box .answer-popup-close-btn img {
  width: 0.52rem;
  height: 0.52rem;
}
.index-box ::v-deep .popup {
  width: 6.8rem !important;
  height: 22.3vh !important;
  margin: 39vh auto 0 !important;
}
.index-box ::v-deep .popup .el-dialog__body {
  width: 6.8rem !important;
  height: 22.3vh !important;
}
.index-box .confirm-popup-box {
  width: 100%;
  height: 100%;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-box .confirm-popup-box .confirm-popup {
  position: relative;
  width: 6.8rem;
  height: 2.4rem;
  background: linear-gradient(180deg, #fdf0db 0%, #fcddc1 100%);
  border-radius: 8px;
  padding: 0.32rem;
}
.index-box .confirm-popup-box .confirm-popup img {
  width: 0.22rem;
  height: 0.22rem;
  position: absolute;
  top: 0.39rem;
  right: 0.32rem;
  cursor: pointer;
}
.index-box .confirm-popup-box .confirm-popup .confirm-popup-title {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #581d00;
  line-height: 1;
  margin-bottom: 0.24rem;
  text-align: left;
}
.index-box .confirm-popup-box .confirm-popup .confirm-popup-content {
  font-size: 0.2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #581d00;
  line-height: 1;
  margin-bottom: 0.38rem;
  text-align: left;
}
.index-box .confirm-popup-box .confirm-popup .confirm-popup-btn-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.confirm-popup-box .confirm-popup .confirm-popup-btn-box .confirm-popup-btn {
  width: 1.2rem;
  height: 0.48rem;
  /* background: linear-gradient(142deg, #FDDF9B 0%, #F8A736 100%); */
  border-radius: 4px;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #581d00;
  line-height: 0.48rem;
  cursor: pointer;
}

.confirm-popup-box
  .confirm-popup
  .confirm-popup-btn-box
  .confirm-popup-btn:hover {
  opacity: 0.85 !important;
}
.confirm-popup-box .confirm-popup .confirm-popup-btn-box .cancel-btn {
  background: linear-gradient(
    142deg,
    rgba(253, 223, 155, 0.23) 0%,
    rgba(248, 167, 54, 0.23) 100%
  );
  margin-right: 0.08rem;
}

.confirm-popup-box .confirm-popup .confirm-popup-btn-box .confirm-btn {
  background: linear-gradient(142deg, #fddf9b 0%, #f8a736 100%);
}
.index-box .content-box .center-box {
  width: 18.68rem;
  /* height: 8.36rem; */
  height: 12.36rem;
  background: transparent;
  background-image: url("./../../assets/images/answerBgNew.png");
  /* background-size: 18.68rem 8.36rem; */
  background-size: 18.68rem 12.36rem;
  background-repeat: no-repeat;
  margin: 0 auto 0.43rem;
  position: relative;
  z-index: 1;
  padding: 0 1.24rem 0 0.84rem;
}
.index-box .content-box .center-box::before {
  content: "";
  width: 1.14rem;
  height: 6.44rem;
  background-image: url("./../../assets/images/leftLight.png");
  background-size: 1.14rem 6.44rem;
  background-repeat: no-repeat;
  position: absolute;
  /* background-color: pink; */
  bottom: -0.5rem;
  left: 0;
  transform: translateX(-50%);
  z-index: -1;
}
.index-box .content-box .center-box::after {
  content: "";
  width: 1.14rem;
  height: 6.44rem;
  background-image: url("./../../assets/images/rightLight.png");
  background-size: 1.14rem 6.44rem;
  background-repeat: no-repeat;
  position: absolute;
  /* background-color: pink; */
  bottom: -0.5rem;
  right: -0.06rem;
  z-index: -1;
}
.index-box .content-box .center-box .type-icon {
  position: absolute;
  top: 0.19rem;
  right: 1.08rem;
  width: 1.35rem;
  height: 1.65rem;
}

.center-box .question-type-title {
  font-size: 0.8rem;
  font-family: YouSheBiaoTiHei;
  color: #f3ffca;
  line-height: 1.04rem;
  letter-spacing: 3px;
  text-shadow: 0px 2px 0px rgba(255, 224, 125, 0.25);
  background: linear-gradient(180deg, #fff9b6 0%, #ffd96e 100%);
  background: -webkit-linear-gradient(180deg, #fff9b6 0%, #ffd96e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.12rem;
}

.center-box .question-type-content {
  width: 100%;
  /* height: 6.3rem; */
  height: 10.2rem;
  display: flex;
  flex-direction: column;
}

.center-box .question-type-content .title-number-box {
  font-size: 0.4rem;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: rgba(88, 29, 0, 0.5);
  line-height: 0.32rem;
  text-align: left;
  flex-shrink: 0;
}
.center-box .question-type-content .title-number-box span {
  /* margin-right: 0.15rem; */
  margin-right: 0;
  font-size: 0.6rem;

  color: #581d00;
  line-height: 0.6rem;
}

.center-box .question-type-content .question-box {
  flex: 1;
  margin-top: 0.3rem;
  /* background-color: pink; */
  overflow: hidden;
  position: relative;
}
.center-box .question-type-content .question-box > div {
  height: 100%;
  overflow: hidden;
}

.question-type-content .question-box .short-answer {
  height: 100%;
  font-size: 0.62rem;
  font-weight: 600;
  color: #581d00;
  line-height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.3rem;
  overflow-y: scroll;
}
.question-type-content .question-box .short-answer::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  /* background: #f4dcbf; */
}
.question-type-content .question-box .short-answer::-webkit-scrollbar-thumb {
  background: #f4dcbf;
  border-radius: 3px;
}
.question-type-content .question-box .choose-answer-box {
  /* position: relative; */
  height: 100%;
  overflow-y: scroll;
}
.question-type-content .question-box .choose-answer-box::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  /* background: #f4dcbf; */
}
.question-type-content
  .question-box
  .choose-answer-box::-webkit-scrollbar-thumb {
  background: #f4dcbf;
  border-radius: 3px;
}
.question-type-content .question-box .choose-answer-box .choose-answer-title {
  /* font-size: 0.38rem; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #581d00;
  /* line-height: 0.7rem; */
  text-align: left;
  font-size: 0.62rem;
  line-height: 0.8rem;
}
.question-type-content .question-box .choose-answer-box .choose-answer-list {
  margin-top: 0.47rem;
  /* font-size: 0.38rem; */
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #581d00;
  /* line-height: 0.4rem; */
  text-align: left;
  margin-left: 0.4rem;
  /* background-color: blue; */
  max-width: 14rem;
  font-size: 0.62rem;
  line-height: 0.8rem;
}
.question-type-content .question-box .choose-answer-box .choose-answer-item {
  margin-bottom: 0.2rem;
}
.question-type-content .question-box .choose-answer-box .answer {
  /* font-size: 0.4rem; */
  font-weight: 600;
  color: #f80c0c;
  /* line-height: 0.4rem; */
  position: absolute;
  bottom: 0.53rem;
  right: 0.3rem;
  font-size: 0.62rem;
  line-height: 0.8rem;
}
.index-box .content-box .operate-btn-box {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: flex-end;
  width: 18.68rem;
}
.content-box .operate-btn-box .btn-item {
  width: 1.6rem;
  height: 0.4rem;
  border-radius: 4px;
  font-size: 0.16rem;
  /* padding: 0.09rem 0.2rem !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.4rem;
  text-align: center;
  margin-right: 0.24rem;
  cursor: pointer;
  transform: scale(1.1);
}

.content-box .operate-btn-box .btn-item:last-child {
  margin-right: 0;
}
.content-box .operate-btn-box .btn-item-1 {
  background: rgba(255, 188, 0, 0.25);

  color: #ffe2a3;
}
.content-box .operate-btn-box .btn-item-2 {
  background: linear-gradient(142deg, #fddf9b 0%, #f8a736 100%);

  color: #581d00;
  line-height: 0.42rem;
}
::v-deep .el-button {
  border: unset !important;
  padding: 0 !important;
}
::v-deep .el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0 !important;
}
.content-box .operate-btn-box .btn-item:hover {
  opacity: 0.85 !important;
}
</style>
