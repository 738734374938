<template>
  <div class="index-box">
    <div class="content-box">
      <div class="type-list">
        <div class="type-item type-item-1" @click="goRiskQuestion('requiredQuestions')">
          <div class="choose-btn-box">
            <div class="choose-btn">单枪匹马</div>
          </div>
        </div>
        <!-- goAnswerPage('preemptive') -->
        <div class="type-item type-item-2" @click="goPreemptivePage">
          <div class="choose-btn-box">
            <div class="choose-btn">奋勇争先</div>
          </div>
        </div>
        <div class="type-item type-item-3" @click="goRiskQuestion('riskQuestions')">
          <div class="choose-btn-box">
            <div class="choose-btn">迎难而上</div>
          </div>
        </div>
      </div>
    </div>
    <div class="back-btn" @click="backIndex">返回</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {};
  },
  methods: {
    goRiskQuestion(questionType) {
      // 选择第一轮 第二轮 20分、30分答题
      this.$router.push("/riskQuestion?questionType=" + questionType);
    },
    // goAnswerPage(value) {
    //   this.$router.push("/answerPage?type=" + value);
    // },
    goPreemptivePage() {
      this.$router.push("/preemptivePage");
    },
    backIndex() {
      this.$router.push({ name: "index" });
    },
  },
};
</script>


<style scoped>
.index-box {
  width: 100%;
  height: 100vh;
  /* background: url("./../../assets/images/bg.jpg") no-repeat;
  background-size: cover;
  background-position: center; */
  padding-top: 2.1rem;
}

.content-box .type-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-box .type-list .type-item {
  width: 4.8rem;
  height: 6.2rem;
  background-size: 4.8rem 6.2rem;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.type-item-1 {
  background-image: url("./../../assets/images/type1.svg");
}
.type-item-2 {
  background-image: url("./../../assets/images/type2.svg");
  margin: 0 0.62rem;
}
.type-item-3 {
  background-image: url("./../../assets/images/type3.svg");
}
.type-list .type-item .choose-btn-box {
  width: 3.24rem;
  height: 1.24rem;
  background: rgba(255, 240, 224, 0.138);
  border-radius: 0.62rem;
  padding: 0.12rem;
  position: absolute;
  bottom: 0.8rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.type-item .choose-btn-box .choose-btn {
  width: 3rem;
  height: 1rem;
  background: #f8d1a7;
  box-shadow: 0px 0px 10px 0px rgba(167, 109, 53, 0.06);
  border-radius: 0.5rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #942913;
  line-height: 1rem;
  letter-spacing: 1px;
}
</style>